import "./VerifyPage.scss";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { getAccessToken, getProfileDataFromLS } from "../../helpers/functions";
import FirstImage from "../../components/ui/images/photo3.png";
import SecondImage from "../../components/ui/images/photo4.png";
import Webcam from "react-webcam";
import VerificationError from "../../components/verification/VerificationError/VerificationError";
import VerificationSuccess from "../../components/verification/VerificationSuccess/VerificationSuccess";
import CameraButton from "../../components/verification/CameraButton/CameraButton";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VerifyPage = () => {
  const { loading, status, userData } = useSelector((state) => state.account);

  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [match, setMatch] = useState(0.0);
  const [stage, setStage] = useState(0.0);
  const [webSocket, setWebSocket] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [error, setError] = useState(false);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  const [faceWidthOne, setFaceSWidthOne] = useState("");
  const [faceWidthTwo, setFaceWidthTwo] = useState("");
  const [faceCount, setFaceCount] = useState("");
  const { t } = useTranslation("global");

  const navigate = useNavigate();

  const handleOkClick = () => {
    setShowFinalModal(false);
    navigate("/profile");
  };

  useEffect(() => {
    let { email, first_name, last_name } = getProfileDataFromLS();

    let token = getAccessToken();
    const ws = new WebSocket("wss://api.jmd.kg/ws/");
    setWebSocket(ws);

    ws.onopen = () => {
      console.log("ws connected");
      setIsWebSocketConnected(true);

      let data = {
        type: "header",
        message: {
          email: email,
          first_name: first_name,
          last_name: last_name,
          token: token,
        },
      };

      const jsonMessage = JSON.stringify(data);

      ws.send(jsonMessage + "\0");
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log(data);
        setFaceSWidthOne(data.face_width1);
        setFaceWidthTwo(data.face_width2);
        setFaceCount(data.face_count);
        setStage(data.stage);
        if (data.stage === 1) {
          setMatch(data.match);
        } else if (data.stage === 2) {
          setFaceSWidthOne(data.face_width1);
          setFaceWidthTwo(data.face_width);
          setFaceCount(data.face_count);
        } else if (data.stage === 3) {
          setIsCameraOn(false);
          setIsWebSocketConnected(false);
          setShowFinalModal(true);
        }
      } catch (e) {
        console.error("Error parsing message:", e);
      }
    };

    ws.onclose = () => {
      console.log("WebSocket Disconnected");
      setIsWebSocketConnected(false);
      setIsCameraOn(false);
      // setError('Не удалось пройти верификацию. Пожалуйста, повторите попытку.');
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error", error);
      setIsWebSocketConnected(false);
      setError(t("verification.error"));
    };

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  const handleStartCaptureClick = () => {
    setIsCameraOn(true);
    setRecordedChunks([]);
    const options = { mimeType: "video/webm" };
    if (webcamRef.current) {
      mediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream,
        options
      );
      mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
        if (event.data.size > 0) {
          onSendVideoStream(event.data);
        }
      });
      mediaRecorderRef.current.start(1000);
    }
  };

  const handleStopCaptureClick = () => {
    mediaRecorderRef.current.stop();
    setIsCameraOn(false);
  };

  const onSendVideoStream = (videoBlob) => {
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      webSocket.send(videoBlob);
    }
  };

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div className="verifyPage container">
      <h2 className="verifyPage__title">
        {isCameraOn ? t("verification.recording") : t("verification.verify")}
      </h2>
      <h3>
        {t("verification.currStage")} {stage}
      </h3>
      {stage === 1 && <h1>{t("verification.stageOne")}</h1>}
      {stage === 2 && <h1>{t("verification.stageTwo")}</h1>}

      <p>
        {t("verification.coincidence")} {Math.floor(match * 100)}%
      </p>
      <div>
        {stage === 2 && faceCount === 1 && (
          <h3 className="redText">{t("verification.warning")}</h3>
        )}
        {faceWidthOne && (
          <h3 className={`${faceWidthOne > 30 ? "greenText" : "redText"}`}>
            {t("verification.size")} 1: {faceWidthOne}{" "}
            {faceWidthOne > 30 ? (
              <p>(oк)</p>
            ) : (
              <p>({t("verification.move")})</p>
            )}
          </h3>
        )}
        {faceWidthTwo && (
          <h3 className={`${faceWidthTwo > 30 ? "greenText" : "redText"}`}>
            {t("verification.size")} 2: {faceWidthTwo}{" "}
            {faceWidthTwo > 30 ? (
              <p>(oк)</p>
            ) : (
              <p>({t("verification.move")})</p>
            )}
          </h3>
        )}
      </div>

      <div className="verificationCamera">
        {isWebSocketConnected && (
          <Webcam audio={false} ref={webcamRef} mirrored={true} />
        )}
        <div>
          {isCameraOn ? (
            <CameraButton
              onClick={handleStopCaptureClick}
              text={t("verification.stop")}
              isCancel={true}
            />
          ) : (
            <CameraButton
              onClick={handleStartCaptureClick}
              text={t("verification.start")}
              isCancel={false}
            />
          )}
          <div className="verificationCamera__ImgContainer">
            {stage === 1 && (
              <img
                className="verificationCamera__Img"
                src={FirstImage}
                alt="passport"
              />
            )}
            {stage === 2 && (
              <img
                className="verificationCamera__Img"
                src={SecondImage}
                alt="passport"
              />
            )}
          </div>
        </div>
      </div>
      {showFinalModal && <VerificationSuccess handleOkClick={handleOkClick} />}
      {error && (
        <VerificationError
          handleReloadClick={handleReloadClick}
          error={error}
        />
      )}
    </div>
  );
};

export default VerifyPage;
