import "./Footer.scss";
import yt from "../images/youtube.svg";
import fb from "../images/facebook.svg";
import ig from "../images/instagram.svg";
import { NavLink } from "react-router-dom";
import AppStore from "../images/App Store - Filled.png";
import GooglePlay from "../images/Google Play.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <footer className="footer">
      <div className="footer__content container">
        {/* <div className='footer__logo'>
          <img src={Logo} alt="transperency" />
        </div> */}
        <div className="footer__menu">
          <ul className="footer__list">
            <p style={{ fontWeight: "500", margin: "0", padding: "0" }}>
              {t("footer.nav")}
            </p>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/"
              >
                {t("footer.main")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/petitions"
              >
                {t("footer.petitions")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/analytics"
              >
                {t("footer.analytics")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/about"
              >
                {t("footer.about")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/privacy"
              >
                {t("footer.privacy")}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="footer__contacts">
          <p style={{ fontWeight: "500" }}>{t("footer.contacts")}</p>
          <p>namename@gmail.com</p>
          <p>+09646895467</p>
          <div>
            <img src={yt} alt="youtube" />
            <img src={fb} alt="facebook" />
            <img src={ig} alt="instagram" />
          </div>
        </div>
        <div className="footer__apps">
          <h5>{t("footer.download")}</h5>
          <div>
            <img src={AppStore} alt="app-store" />
          </div>
          <div>
            <img src={GooglePlay} alt="google-play" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
