import { useNavigate } from "react-router";
import "./VerificationInfo.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VerificationInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  return (
    <div className="verification-info container">
      <h2 className="verification-info__title">{t("verification.title")}</h2>
      <button
        className="verification-info__btn"
        onClick={() => navigate(/*"/verification"*/ "alternative-verification")}
      >
        {t("verification.verify")}
      </button>

      {/*<Link to="alternative-verification">{t("verification.alt")}</Link>*/}
    </div>
  );
};

export default VerificationInfo;
