import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../helpers/axiosConfig";

export const submitVerification = createAsyncThunk(
    'verification/submitVerification',
    async ({ userData }, { dispatch, rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("id_image", userData.id_image);
            formData.append("face_image", userData.face_image);

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            const res = await api.post("/api/users/alt_ver/", formData, config);
            console.log(res);
            if (res.status === 201) {
                return res.status
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
)
